import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { StorageService } from './core/shared/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public productionMode: boolean = environment.production;
  constructor(private storageService: StorageService) {
    this.storageService.init();

  }
}
