import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.css']
})
export class CustomButtonComponent {

  @Output() submitEvent = new EventEmitter<boolean>();

  @Input() text: string;

  constructor() { }


  submit() {
    this.submitEvent.emit(true);
  }

}
