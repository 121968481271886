import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { AnswerCollectionModel } from '../models/answer';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private storageAngular: Storage | null = null;

    constructor(private storage: Storage) {
        this.init();
    }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const storage = await this.storage.create();
        this.storageAngular = storage;
    }

    // Create and expose methods that users of this service can
    // call, for example:
    public set(key: string, value: any): Promise<void> {
        // this.storageAngular?.set(key, value);
        let allItems = [];

        const promise: Promise<void> = new Promise((resolve, reject) => {
            this.storageAngular?.get('items').then((items) => {
                if (items) {
                    allItems = items;
                }
                allItems.push({ key, value });
                this.storageAngular?.set('items', allItems).then(() => {
                    resolve();
                });
            });
        });
        return promise;

    }

    // Create and expose methods that users of this service can
    // call, for example:
    public get(key: string) {
        return this.storageAngular?.get(key);
    }

    public getSizesPreferences(): Promise<any> {
        return this.storageAngular?.get('sizes');
    }

    public setSizesPreferences(sizes): Promise<any> {

        const keys = Object.keys(sizes);


        for (const key of keys) {
            sizes[key] = String(sizes[key]);
        }

        return this.storageAngular?.set('sizes', sizes);
    }

    public keys() {
        // this._storage?.keys().then(data => {
        //     console.log(data)
        // })
        // console.log('storage');

        this.storageAngular?.forEach((key, value, index) => {
            // console.log('key: ', key);
            // console.log('value: ', value);
            // console.log('index: ', index);
        });

        // return this._storage?.keys();
    }

    public getEntries(): Promise<Array<AnswerCollectionModel>> {
        let entries: Array<AnswerCollectionModel> = [];

        const promise: Promise<Array<AnswerCollectionModel>> = new Promise((resolve, reject) => {
            this.storageAngular?.get('items').then((items) => {
                entries = items.map(i => i.value);
                resolve(entries);
            });
        });
        return promise;

    }

    public clear(): Promise<void> {
        const promise: Promise<void> = new Promise((resolve, reject) => this.storageAngular?.clear().then(() => {
            resolve();
        }));
        return promise;
    }
}