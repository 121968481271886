import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PartialsModule } from '../../../views/partials/partials.module';

const SHARED_MODULES = [
  IonicModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  PartialsModule
]

@NgModule({
  imports: [
    SHARED_MODULES,

  ],
  declarations: [],
  exports: [
    SHARED_MODULES
  ]
})
export class SharedModule { }
