import { NgModule } from '@angular/core';
import { CustomButtonComponent } from './custom-button/custom-button.component';


const PARTIALSCOMPONENTS = [
  CustomButtonComponent
];

@NgModule({
  declarations: [PARTIALSCOMPONENTS],
  exports: [PARTIALSCOMPONENTS]
})
export class PartialsModule { }
